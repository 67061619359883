import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import router from '../router';
import {useToast} from 'vue-toastification'
import { portalContadorBaseUrl } from './constans';

const toast = useToast();
let ApiResponse: AxiosResponse;
let ApiError: AxiosError;
const HttpClient: AxiosInstance = axios.create({
  baseURL: portalContadorBaseUrl
});

HttpClient.interceptors.request.use(config => {
  const token = localStorage.getItem('access_token');
  if (token) config.headers.common['Authorization'] = `Bearer ${token}`;
  return config;
});

HttpClient.interceptors.response.use(
  response => {
    ApiResponse = response;
    return ApiResponse;
  },
  error => {
    ApiError = error;
    if (ApiError.response?.status === 401 && router.currentRoute.value.path !== '/login') {
      router.push({ path: '/login' });
      toast.error('Você não está Autenticado', {icon: 'mdi mdi-account-alert'})
    }
    else {
      return ApiError;
    }
  });

export default HttpClient;